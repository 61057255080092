<template>
    <el-drawer
        :title="!formData.id ? '新增用户' : '编辑用户'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
                size="medium"
                autocomplete="off"
            >
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12">
                        <el-form-item prop="name" label="用户名:">
                            <el-input
                                v-model="formData.name"
                                placeholder="请输入用户名"
                                maxlength="10"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item prop="telephone" label="手机号码:">
                            <el-input
                                v-model="formData.telephone"
                                placeholder="请输入手机号码"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item prop="pw" label="登录密码:" :rules="[{ required: id ? false : true, message: '请输入密码' }]">
                            <el-input
                                type="password"
                                v-model="formData.pw"
                                placeholder="请输入登录密码"
                                autocomplete="new-password"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item prop="password1" label="确认密码:">
                            <el-input
                                type="password"
                                v-model="formData.password1"
                                placeholder="请确认密码"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item prop="status" label="状态:">
                            <el-radio-group v-model="formData.status">
                                <el-radio :label="1">正常</el-radio>
                                <el-radio :label="-1">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item prop="privileges" label="用户权限:">
                    <el-tree
                        v-if="reFresh"
                        :data="menuList"
                        show-checkbox
                        node-key="id"
                        ref="permissionTree"
                        :default-checked-keys="defaultCheckedKeys"
                        :props="defaultProps"
                        v-loading="treeLoading"
                    >
                    </el-tree>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">提交</el-button>
        </div>
    </el-drawer>
</template>

<script>
const defaultFormData = {
    "id": "",
    "name": "",
    "privileges": [],
    "pw": "",
    "status": 1,
    "telephone": "",
    password1: '',
}
export default {
    data() {
        return {
            id:'',
            drawerShow: false,
            dataLoading:false,
            btnLoading: false,
            formData: {
                ...defaultFormData
            },
            rules: {
                name: [{ required: true, message: "请输入用户名" }],
                pw: [{ required: true, message: "请输入密码" }],
                password1: [{validator:this.checkPassword1}],
                telephone: [{ required: true, message: "请输入手机号" },{ validator: this.$utils.validatePhone },],
            },
            menuList: [],
            defaultCheckedKeys: [],
            defaultProps: {
                children: "child",
                label: "name",
            },
            treeLoading: false,
            reFresh:false,
        };
    },
    created(){
        this.getAllMenuList();
    },
    methods: {
        checkPassword1 (rule, value, callback) {
            if (value !== this.formData.pw) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        },
        close() {
            this.formData = {...defaultFormData};
            this.$refs.formData.resetFields();
            this.defaultCheckedKeys = [];
            this.drawerShow = false;
        },
        show(id) {
            this.id = id || '';
            if(this.id){
                this.getDataDetail();
            }else{
                this.reFresh = true;
            }
            this.drawerShow = true;
        },
        //获取所有菜单权限
        getAllMenuList(){
            this.treeLoading = true;
            this.$request({
                url: '/api/ht/common/allMenu',
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state) {
                        this.menuList = result || [];
                    } else {
                        this.$message.error(errmsg || "获取权限错误,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取权限错误,请重试");
                })
                .finally(() => {
                    this.treeLoading = false;
                });
        },
        //获取详情
        getDataDetail(){
            this.dataLoading = true;
            this.$request({
                url: '/api/ht/user/info',
                params: {userId: this.id},
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state) {
                        this.formData = {
                            ...defaultFormData,
                            ...result
                        }
                        this.defaultCheckedKeys = result.menuList;
                        this.reFresh = true;
                    } else {
                        this.$message.error(errmsg || "获取详情失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情错误,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //获取选中树
        setPriIdList() {
            const halfKeys = this.$refs.permissionTree.getHalfCheckedKeys() || [];
            const keys = this.$refs.permissionTree.getCheckedKeys() || [];
            this.formData.privileges = halfKeys.concat(keys);
        },
        //表单提交
        submitForm() {
            this.setPriIdList();
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    const _url = this.formData.id
                        ? "/api/ht/user/edit"
                        : "/api/ht/user/add";
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        data: this.formData,
                        method: "post",
                    })
                        .then((res) => {
                            const { state, errmsg } = res.data;
                            if (state) {
                                this.$emit('onUpdate');
                                this.close();
                            } else {
                                this.$message.error(errmsg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>