<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="请输入名称或者电话" v-model="params.keyword" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="search"
                        size="small"
                        >查询</el-button
                    >
                    <el-button
                        type="default"
                        icon="el-icon-refresh"
                        @click="reset"
                        size="small"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </div>
        <div class="page-btns-box">
            <el-button type="primary" plain icon="el-icon-plus" @click="add" size="small">新增用户</el-button>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                border
                style="width: 100%"
                v-loading="listLoading"
                size="mini"
                stripe
            >
                <!-- <el-table-column prop="id" label="ID"></el-table-column> -->
                <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="telephone" label="手机号" min-width="120"></el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="150"></el-table-column>
                <el-table-column fixed="right" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="edit(scope.row.id)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <el-pagination
                v-if="data"
                background
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pn"
                :page-sizes="[15,30,50]"
                :page-size.sync="params.ps"
                layout="total, sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
        <edit ref="userEdit" @onUpdate="getDataList"></edit>
    </div>
</template>
<script>
import Edit from './components/edit';

const defaultParams = {
    pn:1,
    keyword: "",
    ps:15,
}
export default {
    components:{Edit},
    data() {
        return {
            data: null,
            dataList: null,
            params: {
                ...defaultParams
            },
            listLoading: true,
        };
    },
    computed:{
        status(){
            return new Map([
                [-1,'删除'],
                [1,'正常'],
                [0,'禁用'],
            ])
        }
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/api/ht/user/list",
                params: this.params,
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state && result) {
                        this.data = result;
                        this.dataList = result.list || [];
                    } else {
                        this.$message.error(errmsg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pn = 1;
            this.params.ps = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pn = 1;
            this.getDataList();
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                ps: this.params.ps,
            };
            this.getDataList();
        },
        //编辑
        edit(id) {
            this.$refs.userEdit.show(id);
        },
        add(){
            this.$refs.userEdit.show();
        }
    },
};
</script>